import React from 'react';
import { Typography, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Ausenansicht, AfterHour, Festscheune, Innenhof, Trauzelt } from '../images';

/**
 *
 * @param {{ children?: React.ReactNode, titles: string|string[], index: number, image: any }} props
 * @returns
 */
function SingleProgramPoint(props) {
  const { children, titles, index, image, ...other } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const programPointText = (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: {
          xs: 'center',
          md: index % 2 === 0 ? 'right' : 'left',
        },
      }}
    >
      <Typography sx={{ fontSize: '75px'}} variant="h6" component="h2">
        {[titles].flat().map((title, index) => (
          <React.Fragment key={index}>
            {title}
            <br />
          </React.Fragment>
        ))}
      </Typography>
    </Grid>
  );

  const programPointImage = (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        height: '100%',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        paddingTop: '0px',
        justifyContent: {
          xs: 'center',
          md: index % 2 === 0 ? 'flex-start' : 'flex-end',
        },
      }}
    >
      <img src={image} alt={`Programmpunkt ${index}`} style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }} />
    </Grid>
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: { xs: 'auto', md: '500px' },
        paddingBottom: { xs: '100px', md: '0px' },
        '& .MuiGrid-item': { paddingTop: { xs: '0px', md: '16px' } },
      }}
    >
      {isSmallScreen || index % 2 === 0 ? programPointText : programPointImage}
      {isSmallScreen || index % 2 === 0 ? programPointImage : programPointText}
    </Grid>
  );
}

export default function Program() {
  return (
    <div className="section-div transparent-section">
      <span className="font-100">Programm</span>
      <SingleProgramPoint index={0} titles="14:00 Ankunft" image={Ausenansicht} />
      <SingleProgramPoint index={1} titles="14:30 Trauung" image={Trauzelt} />
      <SingleProgramPoint index={2} titles={['15:30 Sektempfang', '16:00 Kuchen']} image={Innenhof} />
      <SingleProgramPoint index={3} titles="18:30 Abendessen" image={Festscheune} />
      <SingleProgramPoint index={4} titles="22:00 Party" image={AfterHour} />
    </div>
  );
}
