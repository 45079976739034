import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Grid } from '@mui/material';
import LazyLoad from 'react-lazyload';
import '../css/Gallery.css';
import '../css/Generic.css';

// Function to import all images from a directory
const importAll = (r) => r.keys().map(r);

// Import images from the firstHoliday and mostRecentHoliday folders
const firstHolidayImages = importAll(require.context('../images/gallery/firstHoliday/slider', false, /\.(png|jpe?g|PNG|JPE?G)$/));
const firstHolidayImagesFullscreen = importAll(require.context('../images/gallery/firstHoliday/fullscreen', false, /\.(png|jpe?g|PNG|JPE?G)$/));
const firstHolidayThumbnails = importAll(require.context('../images/gallery/firstHoliday/thumbnails', false, /\.(png|jpe?g|PNG|JPE?G)$/));
const mostRecentHolidayImages = importAll(require.context('../images/gallery/mostRecentHoliday/slider', false, /\.(png|jpe?g|PNG|JPE?G)$/));
const mostRecentHolidayImagesFullscreen = importAll(require.context('../images/gallery/mostRecentHoliday/fullscreen', false, /\.(png|jpe?g|PNG|JPE?G)$/));
const mostRecentHolidayThumbnails = importAll(require.context('../images/gallery/mostRecentHoliday/thumbnails', false, /\.(png|jpe?g|PNG|JPE?G)$/));

// Convert imported images to the format required by react-image-gallery
const formatImages = (images, thumbnails, fullscreenImages) =>
  images.map((pathToImage, index) => {
    return {
      original: pathToImage,
      thumbnail: thumbnails[index],
      fullscreen: fullscreenImages[index],
      loading: 'lazy'
    };
  });

function handleImageClick(galleryRef) {
  if (!galleryRef.current || document.querySelectorAll('.image-gallery.fullscreen-modal').length) return;
  galleryRef.current.fullScreen();
}

const Gallery = () => {
  const firstHolidayGalleryRef = React.createRef(),
    recentHolidayGalleryRef = React.createRef();
  return (
    <div className="section-div solid-section">
      <span className="font-100">Galerie</span>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <span className="font-50">Erster Urlaub<br/>Menorca 2015</span>
          <LazyLoad height={600} offset={100}>
            <ImageGallery
              ref={firstHolidayGalleryRef}
              useBrowserFullscreen={false} // Disable the fullscreen button
              items={formatImages(firstHolidayImages, firstHolidayThumbnails, firstHolidayImagesFullscreen)}
              additionalClass="fixed-height-gallery"
              showPlayButton={false} // Disable the play button
              autoPlay={false} // Enable automatic iteration
              slideInterval={5000} // Set the interval for automatic iteration (in milliseconds)
              onClick={() => handleImageClick(firstHolidayGalleryRef)}
              lazyLoad={true}
            />
          </LazyLoad>
        </Grid>
        <Grid item xs={12} lg={6}>
          <span className="font-50">Jüngster Urlaub<br/>Tromsö 2024</span>
          <LazyLoad height={600} offset={100}>
            <ImageGallery
              ref={recentHolidayGalleryRef}
              useBrowserFullscreen={false}
              items={formatImages(mostRecentHolidayImages, mostRecentHolidayThumbnails, mostRecentHolidayImagesFullscreen)}
              additionalClass="fixed-height-gallery"
              showPlayButton={false} // Disable the play button
              autoPlay={false} // Enable automatic iteration
              slideInterval={5000} // Set the interval for automatic iteration (in milliseconds)
              onClick={() => handleImageClick(recentHolidayGalleryRef)}
              lazyLoad={true}
            />
          </LazyLoad>
        </Grid>
      </Grid>
    </div>
  );
};

export default Gallery;
