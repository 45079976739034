import React, { useState } from 'react';
import { Button, TextField, MenuItem, Box, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import '../css/SignUpForm.css';
import '../css/Generic.css';

import { makeStyles } from '@mui/styles';

const customTextFontSize = '40px';

const useStyles = makeStyles({
  customIcon: {
    '& .MuiButton-startIcon > *:nth-of-type(1)': {
      fontSize: 40, // Adjust the size here
    },
  },
});

const accomodations = {
  withOvernightStay: { key: 'withOvernightStay', text: 'Mit Übernachtung' },
  withOutOvernightStay: {
    key: 'withOutOvernightStay',
    text: 'Ohne Übernachtung',
  },
};

const SignUpForm = () => {
  const classes = useStyles();
  const [signedUp, setSignedUp] = useState(false);
  const [formFields, setFormFields] = useState([
    {
      name: '',
      accommodation: null,
      nameError: false,
      accommodationError: false,
    },
  ]);

  const handleAddPerson = () => {
    setFormFields([...formFields, { name: '', accommodation: null }]);
  };

  const handleInputChange = (index, event) => {
    const values = [...formFields];
    if (event.target.name === 'name') {
      values[index].name = event.target.value;
      values[index].nameError = false;
    } else {
      values[index].accommodation = event.target.value;
      values[index].accommodationError = false;
    }
    setFormFields(values);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let error = false;
    for (let field of formFields) {
      error = error || !field.name || !field.accommodation;
      !field.name && (field.nameError = true);
      !field.accommodation && (field.accommodationError = true);
    }
    if (error) return;

    const formData = new FormData(event.target),
      body = new URLSearchParams(formData).toString();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    })
      .then(() => setSignedUp(true))
      .catch((error) => alert(error));
  };

  return (
    <div className="section-div solid-section">
      <span className="font-100">Anmeldung</span>
      {!signedUp && <span className="font-50">Bitte nutzt die folgende Form, um euch für die Hochzeit anzumelden.</span>}
      {signedUp ? (
        <Box>
          <span className="font-50">
            Vielen Dank für die Anmeldung. Wir freuen uns auf euch!
            <br />
            Deine Anmeldungen:
            <br />
            {formFields.map((field) => (
              <span>
                {field.name} - {accomodations[field.accommodation]?.text}
                <br />
              </span>
            ))}
          </span>
          <Button
            variant="contained"
            className={classes.customIcon}
            startIcon={<PersonAddIcon />}
            onClick={() => {
              setSignedUp(false);
              setFormFields([
                {
                  name: '',
                  accommodation: null,
                  nameError: false,
                  accommodationError: false,
                },
              ]);
            }}
            sx={{
              width: '95%',
              fontSize: '25px',
              backgroundColor: 'rgb(136, 199, 229)', // Pastel arctic blue
              '&:hover': {
                backgroundColor: 'rgb(108, 178, 211)', // Slightly darker shade for hover effect
              },
              color: '#fff', // Text color
            }}
          >
            Weitere Person hinzufügen
          </Button>
        </Box>
      ) : (
        <Box
          component="form"
          method="post"
          className="sign-up-form"
          onSubmit={handleSubmit}
          netlify
          data-netlify="true"
          name="sign-up"
          sx={{
            width: { xs: '80%', sm: '55%', md: '77%', lg: '60%', xl: '50%' },
          }}
        >
          <input type="hidden" name="form-name" value="sign-up" />
          {formFields.map((field, index) => (
            <Grid container columnGap={1} key={index} className="form-field" direction="row" alignItems="left" spacing={1}>
              <Grid item xs={12} md={5}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="Name"
                  name="name"
                  value={field.name}
                  onChange={(event) => handleInputChange(index, event)}
                  error={field.nameError}
                  helperText={field.nameError ? 'Name is required' : ''}
                  slotProps={{
                    input: {
                      style: {
                        height: '100px',
                        fontSize: customTextFontSize,
                        marginTop: '20px',
                      },
                    },
                    inputLabel: { style: { fontSize: customTextFontSize } },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  required
                  fullWidth
                  select
                  variant="outlined"
                  label="Unterbringung"
                  name="accommodation"
                  value={field.accommodation}
                  onChange={(event) => handleInputChange(index, event)}
                  error={field.accommodationError}
                  helperText={field.accommodationError ? 'Accommodation is required' : ''}
                  slotProps={{
                    input: {
                      style: {
                        height: '100px',
                        fontSize: customTextFontSize,
                        marginTop: '20px',
                      },
                    },
                    inputLabel: { style: { fontSize: customTextFontSize } },
                  }}
                >
                  <MenuItem value={accomodations.withOvernightStay.key} sx={{ fontSize: customTextFontSize }}>
                    {accomodations.withOvernightStay.text}
                  </MenuItem>
                  <MenuItem value={accomodations.withOutOvernightStay.key} sx={{ fontSize: customTextFontSize }}>
                    {accomodations.withOutOvernightStay.text}
                  </MenuItem>
                </TextField>
              </Grid>
              {index > 0 && (
                <Grid item xs={12} md={1}>
                  <IconButton aria-label="delete" onClick={() => setFormFields(formFields.filter((_, i) => i !== index))}>
                    <DeleteIcon
                      sx={{
                        fontSize: '50px',
                        color: 'rgb(240, 139, 154)', // Pastel arctic red
                        '&:hover': {
                          color: 'rgb(255, 0, 38)', // Slightly darker shade for hover effect
                        },
                      }}
                    />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ))}
          <Grid container rowGap={2}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                className={classes.customIcon}
                startIcon={<PersonAddIcon />}
                onClick={handleAddPerson}
                sx={{
                  width: '95%',
                  fontSize: '25px',
                  backgroundColor: 'rgb(136, 199, 229)', // Pastel arctic blue
                  '&:hover': {
                    backgroundColor: 'rgb(108, 178, 211)', // Slightly darker shade for hover effect
                  },
                  color: '#fff', // Text color
                }}
              >
                Weitere Person hinzufügen
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                className={classes.customIcon}
                type="submit"
                startIcon={<HowToRegIcon />}
                sx={{
                  width: '95%',
                  fontSize: '25px',
                  backgroundColor: 'rgb(112, 209, 173)', // Pastel arctic green
                  '&:hover': {
                    backgroundColor: 'rgb(97, 182, 151)', // Slightly darker shade for hover effect
                  },
                  color: '#fff', // Text color
                }}
              >
                Anmelden
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default SignUpForm;
