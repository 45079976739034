import React, { useState, useEffect } from 'react';
import '../css/Header.css';
import '../css/Generic.css';
import { Stack, Grid } from '@mui/material';

/**
 * @returns {{days: number, hours: number, minutes: number, seconds: number}}
 */
function calculateTimeLeft() {
  const difference = new Date('2025-03-29T13:30:00Z') - new Date();

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
}

const Header = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="section-div transparent-section">
      <span className="header-title">Kathrin & Florian</span>
      <span className="header-description">Wir heiraten - 29.03.2025</span>
      <Grid container direction="row" sx={{ justifyContent: 'center' }}>
        <Grid item>
          <Stack direction="column" spacing={1} className="countdown-stack">
            <h2 className="countdown-number">{timeLeft.days}</h2>
            <span className="countdown-text">Tage</span>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="column" spacing={1} className="countdown-stack">
            <h2 className="countdown-number">{timeLeft.hours}</h2>
            <span className="countdown-text">Stunden</span>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="column" spacing={1} className="countdown-stack">
            <h2 className="countdown-number">{timeLeft.minutes}</h2>
            <span className="countdown-text">Minuten</span>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="column" spacing={1} className="countdown-stack">
            <h2 className="countdown-number">{timeLeft.seconds}</h2>
            <span className="countdown-text">Sekunden</span>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;

//Image norway
//"Kathrin & Florian" - große Überschrift
//"Wir heiraten - 29.03.2025" - etwas kleinerer Text
//"Countdown" - größer & schön formatiert --> große Zahl mit - vorne dran. Unter der Zahl etwas kleiner: "days", "hours", "minutes", "seconds"
