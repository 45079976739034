const GutRehbachTopDown = require('./GutRehbachTopDown.jpg'),
  GutRehbachZimmer = require('./GutRehbachZimmer.jpg'),
  GutRehbachHotelIcon = require('./GutRehbachHotelIcon.png'),
  HotelPälzerBuwe = require('./HotelPälzerBuwe.jpg'),
  AfterHour = require('./program/GR-AfterHour.jpg'),
  Festscheune = require('./program/GR-Festscheune.jpg'),
  Innenhof = require('./program/GR-Innenhof.jpg'),
  Ausenansicht = require('./program/GR-Ausenansicht.png'),
  Trauzelt = require('./program/GR-Trauzelt.jpg');

export { GutRehbachTopDown, GutRehbachZimmer, GutRehbachHotelIcon, HotelPälzerBuwe, AfterHour, Festscheune, Innenhof, Ausenansicht, Trauzelt };
