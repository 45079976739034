import '../css/MaintContent.css';
import Header from './Header';
import SignUpForm from './SignUpForm';
import Location from './Location';
import Gallery from './Gallery';
import Program from './Program';
import Contact from './Contact';

function MaintContent() {
  return (
    <div className="main-content-div">
      <Header />
      <SignUpForm />
      <Location />
      <Gallery />
      <Program />
      <Contact />
    </div>
  );
}

export default MaintContent;
