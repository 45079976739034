import React, { useState } from 'react';

import Login from './Login';
import MaintContent from './MainContent';

function LandingPage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div>
      {
        isAuthenticated ? <MaintContent/> : <Login onLogin={setIsAuthenticated}/> //This is the original code
        // isAuthenticated ? <Login onLogin={setIsAuthenticated} /> : <MaintContent /> //This is the modified code
      }
    </div>
  );
}

export default LandingPage;
