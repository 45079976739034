import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GutRehbachTopDown, GutRehbachZimmer, GutRehbachHotelIcon, HotelPälzerBuwe } from '../images';

const gridImageStyle = {
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
};

/**
 *
 * @param {{ children?: React.ReactNode, index: number, selectedTabIndex: number }} props
 * @returns
 */
function LocationTabPanel(props) {
  const { children, selectedTabIndex, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={selectedTabIndex !== index} id={`simple-tabpanel-${index}`} {...other}>
      {selectedTabIndex === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Location() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const updateSelectedTabIndex = (event, newIndex) => {
    setSelectedTabIndex(newIndex);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [gridItemHeight, setGridItemHeight] = useState('auto');
  const firstGridItemRef = useRef(null);

  const changeGridItemHeight = () => {
    const textElement = firstGridItemRef.current;
    if (!textElement) return;

    setGridItemHeight(textElement.clientHeight);

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => setGridItemHeight(entry.contentRect.height));
    });

    resizeObserver.observe(textElement);

    // Cleanup observer on component unmount
    return () => {
      resizeObserver.unobserve(textElement);
      resizeObserver.disconnect();
    };
  };
  useEffect(changeGridItemHeight, [selectedTabIndex]);

  return (
    <div className="section-div transparent-section">
      <span className="font-100">Gut Rehbach</span>
      <Tabs
        value={selectedTabIndex}
        onChange={updateSelectedTabIndex}
        orientation={isSmallScreen ? 'vertical' : 'horizontal'}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'rgb(255, 215, 0)', // Change the indicator color
          },
          '& .MuiTab-root': {
            fontSize: '50px',
            color: 'rgb(255, 242, 208)', // Change the tab label color
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'rgb(255, 215, 0)', // Change the selected tab label color to a fitting color
          },
        }}
      >
        <Tab label="Lokation" />
        <Tab label="Anfahrt" />
        <Tab label="Übernachtung" />
      </Tabs>
      <LocationTabPanel selectedTabIndex={selectedTabIndex} index={0}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '40px', textAlign: 'justify', width: '90%' }}>
            Das Gut Rehbach ist eine idyllische Event- und Hochzeitslocation in Hassloch. Eingebettet am Rande eines lichten Kiefernwaldes
            und direkt am malerischen Rehbach. Seit 2018 steht das liebevoll restaurierte Gut Rehbach für Veranstaltungen zur Verfügung. Der
            Trauplatz bietet eine romantische Umgebung für Trauungen unter freiem Himmel, umgeben von weitläufigen Wiesen und der
            natürlichen Schönheit der Pfalz. Der romantische Innenhof des Guts ist ein charmantes Herzstück der Location, ideal für Empfänge
            und entspannte Zusammenkünfte im Freien. Die Festscheune beeindruckt mit ihrem historischen Fachwerk und rustikalen Steinwänden
            und bietet Platz für bis zu 140 Gäste.
          </Typography>
          <img src={GutRehbachTopDown} alt="Gut Rehbach" style={{ width: '90%' }} />
        </Box>
      </LocationTabPanel>
      <LocationTabPanel selectedTabIndex={selectedTabIndex} index={1}>
        <Typography sx={{ fontSize: '40px', textAlign: 'left' }}>
          <strong>Adresse:</strong> Gut Rehbach, Sägmühlweg 140, 67454 Haßloch
        </Typography>
        <Typography sx={{ fontSize: '40px', textAlign: 'left' }}>
          <strong>Parkmöglichkeiten:</strong> Direkt vor dem Gut gibt es genug kostenlose Paeplätze.
        </Typography>
        <iframe
          title="Gut Rehbach Google Map"
          id="google-map-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5198.526790571287!2d8.252300177662839!3d49.34716307140253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47964bb7ddfa29e9%3A0x2b37c4dce1dba884!2sGut%20Rehbach!5e0!3m2!1sen!2sde"
          width="100%"
          height="600"
          allowFullScreen=""
          loading="eager"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </LocationTabPanel>
      <LocationTabPanel selectedTabIndex={selectedTabIndex} index={2}>
        <Grid container spacing={2}>
          <Grid
            item
            ref={firstGridItemRef}
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: {
                xs: 'center',
                md: 'right',
              },
            }}
          >
            <Typography sx={{ fontSize: '50px' }} variant="h6" component="h2" gutterBottom>
              Übernachtung im Gut Rehbach
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontSize: '40px' }}>
              Zum Gut Rehbach gehört ein Hotel und ein Chalet mit insgesamt 23 Doppel- und 1 Einzelzimmer, die wir bereits gebucht haben.
              Wir bitten um Verständnis, dass wir diese Zimmer erst mit Familienmitgliedern und danach mit Freunden auffüllen werden, wobei
              gilt: der Schnellere gewinnt. Sollten mehr als 47 Gäste übernachten wollen, bitten wir den Rest darum, eigenständig
              Übernachtungsmöglichkeiten zu organisieren.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: {
                xs: '100%',
                md: gridItemHeight,
              },
              display: 'flex',
              justifyContent: {
                xs: 'center',
                md: 'flex-start',
              },
            }}
          >
            <img src={GutRehbachZimmer} alt="Gut Rehbach Zimmer" style={gridImageStyle} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: {
                xs: '100%',
                md: gridItemHeight,
              },
              display: 'flex',
              justifyContent: {
                xs: 'center',
                md: 'flex-end',
              },
            }}
          >
            <img src={GutRehbachHotelIcon} alt="Gut Rehbach" style={gridImageStyle} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: {
                xs: 'center',
                md: 'left',
              },
            }}
          >
            <Typography sx={{ fontSize: '50px' }} variant="h6" component="h2" gutterBottom>
              Wichtige Infos
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontSize: '40px' }}>
              Check-In: Ab 14:00 Uhr (gerne auch nach der Trauung)
              <br />
              Check-Out: Bis 11:00 Uhr
              <br />
              Frühstück: 9:00 - 12:00 Uhr
              <br />
              Kosten: 95€ pro Person
              <br />
              Nach der Anmeldefrist melden wir uns bei euch bezüglich der Zimmerverteilung.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: {
                xs: 'center',
                md: 'right',
              },
            }}
          >
            <Typography sx={{ fontSize: '50px' }} variant="h6" component="h2" gutterBottom>
              Hotel Pälzer-Buwe
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ fontSize: '40px' }}>
              Gerade mal 2 km entfernt im Haßlocher Stadtzentrum befindet sich das{' '}
              <a href="https://www.paelzer-buwe.de/" style={{ color: 'rgb(255, 215, 0)' }}>
                Hotel Pälzer-Buwe
              </a>
              , das uns vom Gut Rehbach empfohlen wurde. Dieses familiengeführte Hotel bietet komfortable Zimmer mit Balkon oder Terrasse,
              TV, WLAN, Minibar und eigenem Bad. Das Hotel verfügt über ein traditionelles Restaurant, das regionale deutsche Küche
              serviert, und bietet kostenfreie Parkplätze.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: {
                xs: '100%',
                md: gridItemHeight,
              },
              display: 'flex',
              justifyContent: {
                xs: 'center',
                md: 'flex-start',
              },
            }}
          >
            <img src={HotelPälzerBuwe} alt="Hotel Pälzer-Buwe" style={gridImageStyle} />
          </Grid>
        </Grid>
      </LocationTabPanel>
    </div>
  );
}
