// filepath: /c:/SAPDevelop/GitHub/react-app-hz/src/js/Contact.js
import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import '../css/Contact.css';

export default function Contact() {
  const contacts = [
    { name: 'Kathrin', phone: '0173 3116929' },
    { name: 'Florian', phone: '0178 8762935' },
    { name: 'Rebecca', phone: '0152 57482965' },
    { name: 'Alex', phone: '0176 63236014' },
    { name: 'Max', phone: '0176 63328420' },
  ];

  const mainContacts = contacts.slice(0, 2); // Kathrin und Florian
  const otherContacts = contacts.slice(2); // Andere Kontakte

  return (
    <div className="section-div solid-section">
      <span className="font-100">Kontakt</span>
      <Box className="contact-section" sx={{ marginTop: 2, marginBottom: 10 }}>
        <Grid container spacing={2}>
          {mainContacts.map((contact, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box className="contact-card">
                <Typography sx={{ fontSize: '50px', color: 'rgb(223, 193, 119)' }} variant="h6" className="contact-name">
                  {contact.name}
                </Typography>
                <Typography sx={{ fontSize: '50px', color: 'rgb(177, 151, 86)' }} variant="body1" className="contact-phone">
                  {contact.phone}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box className="contact-section" sx={{ marginTop: 2 }}>
        <span className="font-75">Trauzeugen</span>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {otherContacts.map((contact, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Box className="contact-card">
                <Typography sx={{ fontSize: '50px', color: 'rgb(223, 193, 119)' }} variant="h6" className="contact-name">
                  {contact.name}
                </Typography>
                <Typography sx={{ fontSize: '50px', color: 'rgb(177, 151, 86)' }} variant="body1" className="contact-phone">
                  {contact.phone}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
